import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';
import './homework-modal.css';

interface HomeworkAnswer { id: number; live_id: number; correct_answer: number; }
interface HomeworkSubmit { id: number; user_id: number; student_answer: number; question_id: number; live_id: number }



const ModalHomework = (props: {
    isOpen: boolean, onClose: () => void, fullname: string, userId: string, liveId: number, subject: string, startTime: string
}) => {

    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const playlists = useSelector((state: ApplicationState) => state.toggle.playlists);
    const toggleState = useSelector((state: ApplicationState) => state.toggle);

    const [editedAnswers, setEditedAnswers] = useState<{ [id: number]: number }>({});
    const [editedStudentAnswers, setEditedStudentAnswers] = useState<{ [id: number]: number }>({});
    const [hasChanges, setHasChanges] = useState(false);
    const [homework, setHomework] = useState<HomeworkAnswer[]>([]);
    const [homeworkSubmit, setHomeworkSubmit] = useState<HomeworkSubmit[]>([]);
    const [loading, setLoading] = useState(true);
    const [percentageData, setPercentageData] = useState<Record<number, number>>({});
    const [score, setScore] = useState<{ correctAnswers: number, totalQuestions: number }>({ correctAnswers: 0, totalQuestions: 0 });
    const [showInsertConfirmation, setShowInsertConfirmation] = useState(false);

    const answerMapping: { [key: number]: string } = { 1: 'ก.', 2: 'ข.', 3: 'ค.', 4: 'ง.', 5: 'จ.' };
    const playlistId = toggleState.toggle.split('-')[1];
    const { token } = loginState.data;

    const currentPlaylist = playlists.find(playlist => playlist.id == playlistId)?.name || 'Unknown Playlist';
    const currentInstructor = playlists.find(playlist => playlist.id == playlistId)?.instructor || 'Unknown Instructor';



    const fetchHomework = async (liveId: number) => {
        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}/v1/school/homework?liveId=${liveId}`;
            const response = await callApi('GET', url, token);

            const data: HomeworkAnswer[] = response.data;
            const sortedData = data.sort((a, b) => a.id - b.id);
            setHomework(sortedData); setHasChanges(false);

        } catch (error) {
            console.error('Error fetching homework:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatStartTime = (starttime: string) => moment(starttime).format('DD MMM, YY');

    const insertHomework = async (liveId: number) => {
        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}/v1/school/homework/insert`;
            const body = { liveId }

            await callApi('POST', url, token, body);
            fetchHomework(liveId);

        } catch (error) {
            console.error('Error inserting homework:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInsertConfirm = () => {
        insertHomework(props.liveId); setShowInsertConfirmation(false);
    };

    const fetchPercentageData = async (questionId: number) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/homework/percentage-stats?questionId=${questionId}`;
            const response = await callApi('GET', url, token);
            return response;
        } catch (error) {
            console.error('Error fetching percentage data:', error);
            return null;
        }
    };

    const handlePercentage = async (questionIds: number[]) => {
        const percentages = await Promise.all(
            questionIds.map(async (questionId) => {
                const data = await fetchPercentageData(questionId);
                const percentage = data ? (data.correct_count / data.total_count) * 100 : 0;
                return { questionId, percentage };
            })
        );
        const percentageMap: Record<number, number> = {};
        percentages.forEach(({ questionId, percentage }) => { percentageMap[questionId] = percentage });
        setPercentageData(percentageMap);
    };

    const handleAnswerChange = (id: number, newAnswer: number) => {
        setEditedAnswers(prev => {
            const newEditedAnswers = { ...prev, [id]: newAnswer };
            const hasChanges = Object.keys(newEditedAnswers).length > 0 ||
                homework.some(hw => editedAnswers[hw.id] !== undefined && editedAnswers[hw.id] !== hw.correct_answer);
            setHasChanges(hasChanges);
            return newEditedAnswers;
        });
    };

    const fetchHomeworkSubmit = async (liveId: number, userId: number) => {
        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}/v1/school/homework-submission?liveId=${liveId}&userId=${userId}`;
            const response = await callApi('GET', url, token);

            const data: HomeworkSubmit[] = response.data;
            setHomeworkSubmit(data);
        } catch (error) {
            console.error('Error fetching homework submit:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleStudentAnswerChange = (id: number, newAnswer: number) => {
        setEditedStudentAnswers(prev => ({ ...prev, [id]: newAnswer }));
        setHasChanges(true);
    };

    const calculateScore = () => {
        const totalQuestions = homework.length;
        const correctAnswers = homework.filter(({ id, correct_answer }) => {
            const studentAnswer = editedStudentAnswers[id] !== undefined
                ? editedStudentAnswers[id]
                : homeworkSubmit.find(submit => submit.question_id === id)?.student_answer || 0;
            return studentAnswer === correct_answer;
        }).length;

        return { correctAnswers, totalQuestions };
    };

    const updateHomeworkAnswer = async (updates: { questionId: number, correctAnswer: number }[]) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/homework/update-answer`;
            await callApi('PUT', url, token, { updates });
        } catch (error) {
            console.error('Error updating homework answers:', error);
        }
    };

    const upsertStudentAnswer = async (updates: { userId: string, studentAnswer: number, questionId: number, liveId: number }[]) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/homework/student/upsert-answers`;
            await callApi('PUT', url, token, { updates });
        } catch (error) {
            console.error('Error upserting student answers:', error);
        }
    };

    const handleSave = async () => {
        try {
            setLoading(true);

            const homeworkUpdates = homework.map(({ id, correct_answer }) => ({
                questionId: id,
                correctAnswer: editedAnswers[id] !== undefined ? editedAnswers[id] : correct_answer
            }));
            await updateHomeworkAnswer(homeworkUpdates);

            const studentUpdates = homework
                .map(({ id }) => ({
                    userId: props.userId,
                    studentAnswer: editedStudentAnswers[id] !== undefined ? editedStudentAnswers[id] : 0,
                    questionId: id,
                    liveId: props.liveId
                }))
                .filter(update => update.studentAnswer !== 0);
            if (studentUpdates.length > 0) await upsertStudentAnswer(studentUpdates);

            props.onClose();

        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        if (props.isOpen) {
            setEditedAnswers({}); setEditedStudentAnswers({});
            fetchHomework(props.liveId); fetchHomeworkSubmit(props.liveId, Number(props.userId));
        }
    }, [props.isOpen, props.liveId, props.userId]);

    useEffect(() => {
        if (homework.length > 0) {
            const { correctAnswers, totalQuestions } = calculateScore();
            handlePercentage(homework.map(({ id }) => id));
            setScore({ correctAnswers, totalQuestions });
        }
    }, [homework, editedStudentAnswers, homeworkSubmit]);



    if (!props.isOpen) return null;

    return (
        <div className="homework-modal-overlay" onClick={props.onClose}>
            <div className="homework-modal-content" onClick={(e) => e.stopPropagation()}>

                <div className="homework-modal-header">
                    <img className="homework-modal-logo" src="/nockacademy-logo.png" alt="NockAcademy Logo" />
                    <h2>Live Quiz – {props.fullname}</h2>
                    <div>
                        <p>Course – {currentPlaylist}</p>
                        <p>Lesson – {props.subject} {`(${formatStartTime(props.startTime)})`}</p>
                        <p>Teacher – {currentInstructor}</p>
                    </div>
                </div>

                <div className="homework-modal-insert" onClick={() => setShowInsertConfirmation(true)}>
                    <div id="plusSymbol">+</div>
                </div>

                {loading ? (
                    <div className="homework-modal-loading-spinner">
                        <PulseLoader color="#0b7aff" />
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Correct Answer</th>
                                <th>Correct Percentage</th>
                                <th>Student's Answer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homework.length === 0 ? (
                                <tr>
                                    <td colSpan={4} className="empty-message">No data available</td>
                                </tr>
                            ) : (
                                homework.map(({ id, correct_answer }, index) => {
                                    const studentAnswer = editedStudentAnswers[id] !== undefined
                                        ? editedStudentAnswers[id]
                                        : homeworkSubmit.find(submit => submit.question_id === id)?.student_answer || 0;

                                    const percentage = percentageData[id];
                                    const selectClassName = studentAnswer === correct_answer
                                        ? 'homework-modal-correct-answer'
                                        : studentAnswer !== 0
                                            ? 'homework-modal-incorrect-answer'
                                            : '';

                                    return (
                                        <tr key={id}>
                                            <td className="homework-modal-table-single">
                                                {index + 1}
                                            </td>
                                            <td className="homework-modal-table-single">
                                                <select
                                                    value={editedAnswers[id] !== undefined ? editedAnswers[id] : correct_answer}
                                                    onChange={(e) => handleAnswerChange(id, Number(e.target.value))}
                                                >
                                                    {Object.entries(answerMapping).map(([value, label]) => (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="homework-modal-table-single">
                                                {percentage !== undefined ? `${percentage.toFixed(2)}%` : '0%'}
                                            </td>
                                            <td className="homework-modal-table-single">
                                                <select
                                                    className={selectClassName}
                                                    value={studentAnswer}
                                                    onChange={(e) => handleStudentAnswerChange(id, e.target.value === '' ? 0 : Number(e.target.value))}
                                                >
                                                    <option value="">
                                                        -
                                                    </option>
                                                    {Object.entries(answerMapping).map(([value, label]) => (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                        {homework.length > 0 && (
                            <tfoot className="livestream-modal-table-footer">
                                <tr>
                                    <td colSpan={2}></td>
                                    <td>Score</td>
                                    <td>{`${score.correctAnswers}/${score.totalQuestions}`}</td>
                                </tr>
                            </tfoot>
                        )}
                    </table>
                )}

                {homework.length > 0 && hasChanges && (
                    <div className="homework-modal-save-button">
                        <button onClick={handleSave}>Save</button>
                    </div>
                )}

            </div>

            {showInsertConfirmation && (
                <div
                    className="homework-modal-confirmation-dialog-overlay"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="homework-modal-confirmation-dialog-content">
                        <h3>Are you sure you want to insert the homework data?</h3>
                        <div className="homework-modal-confirmation-dialog-actions">
                            <button onClick={() => handleInsertConfirm()}>Yes</button>
                            <button onClick={() => setShowInsertConfirmation(false)}>No</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};



export default ModalHomework;
