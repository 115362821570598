import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { callApi } from '../../utils/api';
import { Playlist } from '../../store/toggle/types';
import PulseLoader from 'react-spinners/PulseLoader';
import { setPlaylists } from '../../store/toggle/actions';
import './table.css';
import './playlist-table.css';



const PlaylistTable = () => {

    const dispatch = useDispatch();
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [addId, setAddId] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [playlists, setPlaylistsLocal] = useState<Playlist[]>([]);



    const getFirstInstructorName = (instructorNames: string) => {
        const match = instructorNames.match(/^([^,]+)/);
        return match ? match[1].trim() : instructorNames;
    };

    const getSchoolPlaylists = async () => {
        try {
            const { token } = loginState.data;
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school`, token);

            const processedPlaylists = response.data.map((playlist: Playlist) => ({
                ...playlist,
                instructor: getFirstInstructorName(playlist.instructor),
            }));

            setPlaylistsLocal(processedPlaylists);
            dispatch(setPlaylists(processedPlaylists));
        } catch (error) {
            console.error('Error fetching playlists:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async (id: string) => {
        try {
            const { token } = loginState.data;
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school/toggle`, token, { id });
            getSchoolPlaylists();
        } catch (error) {
            console.error('Error adding playlist:', error);
        }
    };

    const handleAddInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddId(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && addId.trim()) {
            handleAdd(addId.trim());
            setAddId('');
        }
    };

    const handleRemove = async (id: string) => {
        try {
            const { token } = loginState.data;
            await callApi('POST', `${process.env.REACT_APP_API_URL}/v1/live-stream/lsplayback/school/toggle`, token, { id });
            setPlaylistsLocal(playlists.filter(playlist => playlist.id !== id));
            dispatch(setPlaylists(playlists.filter(playlist => playlist.id !== id)));
        } catch (error) {
            console.error('Error removing playlist:', error);
        }
    };



    useEffect(() => {
        if (loginState.data) getSchoolPlaylists();
    }, [loginState.data]);



    return (
        <table className="table-container">
            <thead>
                <tr>
                    <th className="table-id">ID</th>
                    <th className="table-instructor">Instructor</th>
                    <th className="table-name">Name</th>
                    <th className="table-add">
                        <input
                            type="text" value={addId}
                            onChange={handleAddInputChange} onKeyDown={handleKeyDown}
                            placeholder="Enter Playlist ID"
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                {loading ? (
                    <tr>
                        <td colSpan={4}>
                            <div className="loader-container">
                                <PulseLoader color="#0b7aff" />
                            </div>
                        </td>
                    </tr>
                ) : playlists.length ? (
                    playlists.map((playlist) => (
                        <tr key={playlist.id}>
                            <td className="table-id">{playlist.id}</td>
                            <td className="table-instructor">{playlist.instructor}</td>
                            <td>{playlist.name}</td>
                            <td className="table-add">
                                <button className="remove-button" onClick={() => handleRemove(playlist.id)}>Remove</button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={4} className="no-playlists">No Playlists Available</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default PlaylistTable;
